var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Company")])]),_c('v-toolbar',{attrs:{"dense":""}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-icon":"mdi-magnify","single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearchEnter($event)}},model:{value:(_vm.search_q),callback:function ($$v) {_vm.search_q=$$v},expression:"search_q"}})],1),(_vm.search_comps)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.company_headers,"items":_vm.search_comps,"server-items-length":_vm.search_total_results,"options":_vm.options,"items-per-page":_vm.num_per_page,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [_vm.num_per_page],
        },"no-data-text":"No companies found"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.addCompany(item)}}},[_vm._v(" mdi-plus ")])]}}],null,false,4241270951)}):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.finishedAddedCompanies}},[_vm._v(" Close ")])],1)],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v("Clients"),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.openAddCompanies}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),(_vm.clients)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clients,"sort-by":_vm.sortBy,"items-per-page":25,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 25, -1],
      },"no-data-text":"No clients available"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.gotoClient(item)}}},[_vm._v(" mdi-view-list ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteClient(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,4031709025)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }