<template>
  <v-container>
    <v-layout row v-if="error">
      <v-flex xs12 sm6 offset-sm3>
        <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3 class="text-xs-center" mt-5>
        <v-card>
          <v-card-text>
            <v-container>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="email"
                      label="Username"
                      id="email"
                      v-model="inputs.username"
                      type="email"
                      :rules="emailRules"
                      autocomplete="email"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="password"
                      label="Password"
                      id="password"
                      v-model="inputs.password"
                      type="password"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-btn @click="login(inputs)" :disabled="!valid"
                      >Sign In</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      inputs: {
        username: '',
        password: '',
      },
      valid: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid',
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'error']),
  },
  watch: {
    isAuthenticated(value) {
      if (value) {
        this.$store.dispatch('clients/loadMe');
        this.$router.push('/');
      }
    },
  },
  methods: {
    login({ username, password }) {
      this.$store.dispatch('auth/token', { username, password });
    },
    // onSignin () {
    //   this.$store.dispatch('signUserIn', {
    //     username: this.email,
    //     password: this.password
    //   }).then(() => this.$router.push('/'))
    // },
    onDismissed() {
      console.log('Dismissed Alert!');
      this.$store.dispatch('clearError');
    },
  },
};
</script>
