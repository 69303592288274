import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Clients from '../views/Clients.vue';
import SignIn from '../views/SignIn.vue';
import Client from '../views/Client2.vue';
import Upload from '../views/Upload.vue';
import FileRows from '../views/FileRows.vue';
import store from '@/store';

Vue.use(VueRouter);

const requireAuthenticated = (to, from, next) => {
  store.dispatch('auth/initialize').then(() => {
    if (!store.getters['auth/isAuthenticated']) {
      next('/login');
    } else {
      next();
    }
  });
};

const requireUnauthenticated = (to, from, next) => {
  store.dispatch('auth/initialize').then(() => {
    if (store.getters['auth/isAuthenticated']) {
      next('/');
    } else {
      next();
    }
  });
};

const redirectLogout = (to, from, next) => {
  store.dispatch('auth/logout').then(() => next('/login'));
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/upload/:id',
    name: 'Upload',
    props: true,
    component: Upload,
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/filerows/:id',
    name: 'FileRows',
    props: true,
    component: FileRows,
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/client/:id',
    name: 'Client',
    props: true,
    component: Client,
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/login',
    name: 'SignIn',
    component: SignIn,
    beforeEnter: requireUnauthenticated,
  },
  {
    path: '/logout',
    beforeEnter: redirectLogout,
  },
];

const router = new VueRouter({
  //   mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
