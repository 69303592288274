<template>
  <div id="wrapper">
    <div id="main">
      <div class="inner">
        <div id="image01" class="image">
          <v-img
            :src="require('../assets/todimage.png')"
            class="my-3"
            contain
            height="200"
          />
        </div>
        <h1 id="text01">Tax on Demand</h1>
        <hr id="divider01" />
        <p id="text02">Truly Intelligent Tax Automation</p>
        <ul id="buttons01" class="buttons">
          <li>
            <v-btn :to="{ name: 'Clients' }" class="button n01">
              <span class="mr-2">Start</span>
            </v-btn>
          </li>
        </ul>
        <p id="text03">
          Tax on Demand is the brand name of On Demand Technology Limited
          (Company number 13334851). Registered office: Lumaneri House Blythe
          Gate, Blythe Valley Park, Solihull, United Kingdom, B90 8AH. Trading
          Office: Suite 5, Bloxam Court, Corporations Street, Rugby, CV21 2DU.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style scoped>
#wrapper {
  -webkit-overflow-scrolling: touch;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 1.75rem 1.75rem 1.75rem 1.75rem;
}
#main {
  min-width: 320px;
  min-height: 100vh;
  line-height: 1;
  word-wrap: break-word;
  overflow-x: hidden;
  background-color: #000000;
}

#main > .inner {
  position: relative;
  z-index: 1;
  border-radius: inherit;
  padding: 3.5rem 2.75rem;
  max-width: 100%;
  width: 28rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

#main > .inner > * {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#main > .inner > :first-child {
  margin-top: 0 !important;
}

#main > .inner > :last-child {
  margin-bottom: 0 !important;
}

#main > .inner > .full {
  margin-left: calc(-2.75rem);
  width: calc(100% + 5.5rem + 0.4725px);
  max-width: calc(100% + 5.5rem + 0.4725px);
}

#main > .inner > .full:first-child {
  margin-top: -3.5rem !important;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

#main > .inner > .full:last-child {
  margin-bottom: -3.5rem !important;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

#main > .inner > .full.screen {
  width: 100vw;
  max-width: 100vw;
  position: relative;
  border-radius: 0 !important;
  left: 50%;
  right: auto;
  margin-left: -50vw;
}
#text01 br + br {
  margin-top: 0.675rem;
}

#text01 {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.75em;
  line-height: 1.125;
  font-weight: 400;
}

#text01 a {
  text-decoration: underline;
}

#text01 a:hover {
  text-decoration: none;
}

#text02 br + br {
  margin-top: 0.975rem;
}

#text02 {
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1em;
  line-height: 1.625;
  font-weight: 300;
}

#text02 a {
  text-decoration: underline;
}

#text02 a:hover {
  text-decoration: none;
}

#text03 br + br {
  margin-top: 1.2rem;
}

#text03 {
  text-align: center;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.075rem;
  width: calc(100% + 0.075rem);
  font-size: 0.5em;
  line-height: 2;
  font-weight: 400;
  display: none;
}

#text03 a {
  text-decoration: underline;
}

#text03 a:hover {
  text-decoration: none;
}

hr {
  width: 100%;
  position: relative;
  padding: 0;
  border: 0;
}

hr:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
}

#divider01 {
  text-align: center;
}
#divider01:not(:first-child) {
  margin-top: 0.25rem !important;
}

#divider01:not(:last-child) {
  margin-bottom: 0.25rem !important;
}

#divider01:before {
  width: 13%;
  border-top: solid 1px #ffffff;
  height: 1px;
  margin-top: -0.5px;
}

.buttons {
  text-align: center;
  cursor: default;
  padding: 0;
  letter-spacing: 0;
}

.buttons li a {
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  max-width: 100%;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  vertical-align: middle;
}

#buttons01 {
  width: calc(100% + 0.75rem);
  margin-left: -0.375rem;
}

#buttons01 li {
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 0.75rem);
  margin: 0.375rem;
}

#buttons01 li a {
  display: flex;
  width: 15rem;
  height: 3.25rem;
  line-height: 3.25rem;
  vertical-align: middle;
  padding: 0 1.625rem;
  text-transform: uppercase;
  font-size: 0.75em;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.275rem;
  padding-left: calc(0.275rem + 1.625rem);
  font-weight: 400;
  border-radius: 2rem;
}

#buttons01 .button {
  background-color: #42b398;
  color: #ffffff;
}

#buttons01 .button:hover {
  background-color: #3ec2a2 !important;
}

#buttons01 .n01 {
  background-color: #7851a9;
}
</style>
