import session from './session';

let baseUrl = ''; // for distribution with Django backend
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://127.0.0.1:8000';
}

export default {
  login(username, password) {
    return session.post(`${baseUrl}/rest-auth/login/`, { username, password });
  },
  token(username, password) {
    console.log('token', { username, password });
    return session.post(`${baseUrl}/api/token/`, { username, password });
  },
  logout() {
    console.log('post', `${baseUrl}/rest-auth/logout/`);
    return session.post(`${baseUrl}/rest-auth/logout/`, {});
  },
};
