<template>
  <v-card v-if="file" class="mt-4">
    <v-card-title
      ><v-btn icon @click="goBack"><v-icon>mdi-arrow-left</v-icon></v-btn
      >{{ fileName }}</v-card-title
    >
    <v-simple-table v-if="rows">
      <template v-slot:default>
        <tbody>
          <tr v-for="(row, idx) in rows" :key="idx">
            <td>{{ row[0] }}</td>
            <td>{{ row[1] }}</td>
            <td>{{ row[2] }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FileRows',
  props: ['id'],
  data() {
    return {};
  },
  computed: {
    isAuthorised() {
      // return this.$store.getters.isAuthorised
      return this.$store.getters['auth/isAuthenticated'];
    },
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('files', ['file', 'rows', 'file_error', 'file_loading']),
    fileName() {
      if (this.file) {
        const url = this.file.file;
        if (url) {
          const parts = url.split('/');
          return parts[parts.length - 1];
        }
      }
      return null;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    console.log('authorised', this.isAuthenticated);
    if (this.isAuthenticated) {
      let id = this.id;
      this.$store.dispatch('files/loadFile', { id });
      this.$store.dispatch('files/loadFileRows', { id });
    } else {
      console.log('Bah! Not Authorised');
      this.$router.push({ name: 'SignIn' });
    }
  },
};
</script>
