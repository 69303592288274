import session from './session';

let baseUrl = ''; // for distribution with Django backend
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://127.0.0.1:8000';
}

export default {
  loadFile(id) {
    return session.get(`${baseUrl}/api/files/${id}/`);
  },
  loadFileRows(id) {
    return session.get(`${baseUrl}/api/files/${id}/rows/`);
  },
};
