<template>
  <div class="container">
    <!--UPLOAD-->
    <v-card v-if="client">
      <v-card-title
        ><v-btn icon @click="goBack"><v-icon>mdi-arrow-left</v-icon></v-btn
        >{{ client.name }}</v-card-title
      >
      <v-card-subtitle>Upload</v-card-subtitle>
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
      >
        <div class="dropbox">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="
              filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
            accept="image/*"
            class="input-file"
          />
          <p v-if="isInitial || isSuccess">
            Drag your file(s) here to begin<br />
            or click to browse
          </p>
          <p v-if="isSaving">Uploading file...</p>
        </div>
      </form>
      <div v-if="isSuccess" class="success">
        <p>Upload Success</p>
      </div>
      <div v-if="isFailed" class="failure">
        <p>Upload Failed</p>
      </div>

      <p v-if="isFailed">Upload Failed</p>
    </v-card>
    <v-card v-if="client_files">
      <v-card-title>Evidence Files</v-card-title>
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(cfile, idx) in client_files" :key="idx">
                  <td>{{ cfile.file.substring(7) }}</td>
                  <td>{{ new Date(cfile.uploaded_at).toLocaleString() }}</td>
                  <td>
                    <v-btn icon @click="deleteFile(cfile)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<!-- Javascript -->
<script>
import { mapGetters } from 'vuex';
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  props: ['id'],
  name: 'app',
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'file',
      reloadTimer: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('clients', ['client', 'client_files', 'error', 'loading']),
    isAuthorised() {
      // return this.$store.getters.isAuthorised
      return this.$store.getters['auth/isAuthenticated'];
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  watch: {
    $route: 'reMount',
    loading(value) {
      if (!value && this.currentStatus === STATUS_SAVING) {
        if (this.error) {
          this.currentStatus = STATUS_FAILED;
        } else {
          this.currentStatus = STATUS_SUCCESS;
        }
        this.triggerReloadTimer();
      }
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      this.$store.dispatch('clients/uploadFile', { formData });

      //   upload(formData)
      //     .then((x) => {
      //       this.uploadedFiles = [].concat(x);
      //       this.currentStatus = STATUS_SUCCESS;
      //     })
      //     .catch((err) => {
      //       this.uploadError = err.response;
      //       this.currentStatus = STATUS_FAILED;
      //     });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      formData.append('client', this.id);

      // save it
      this.save(formData);
    },
    deleteFile(cfile) {
      console.log('deleteFile', cfile);
      let id = cfile.id;
      this.$store.dispatch('clients/deleteFile', { id });
      this.triggerReloadTimer();
    },
    reMount() {
      console.log('reMount');
      this.reset();
      let id = this.id;
      this.$store.dispatch('clients/loadClient', { id });
      this.$store.dispatch('clients/loadClientFiles', { id });
    },

    triggerReloadTimer() {
      this.reloadTimer = setTimeout(this.reMount, 1500);
    },
    cancelAutoUpdate() {
      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.isAuthorised) {
      this.reMount();
      //   let id = this.id;
      //   this.$store.dispatch('clients/loadClient', { id });
      //   this.$store.dispatch('clients/loadClientDocs', { id });
      //   this.$store.dispatch('clients/loadClientOverrides', { id });
    } else {
      this.$router.push('/login');
    }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.success {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightgreen;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.success p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.failure {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightpink;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.failure p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
