export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const LOADING_BEGIN = 'LOADING_BEGIN';
export const LOADING_FAILURE = 'LOADING_FAILURE';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLIENT_DOCS = 'SET_CLIENT_DOCS';
export const SET_CLIENT_FILES = 'SET_CLIENT_FILES';
export const SET_ME = 'SET_ME';
export const SET_CLIENT_OVERRIDES = 'SET_CLIENT_OVERRIDES';
export const SET_FILE = 'SET_FILE';
export const SET_FILE_ROWS = 'SET_FILE_ROWS';
export const SET_COMPANIES = 'SET_COMPANIES';
