<template>
  <v-container fluid>
    <v-dialog v-model="addDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Company</span>
        </v-card-title>
        <v-toolbar dense>
          <v-text-field
            hide-details
            prepend-icon="mdi-magnify"
            single-line
            v-on:keyup.enter="onSearchEnter"
            v-model="search_q"
          ></v-text-field>
        </v-toolbar>
        <v-data-table
          v-if="search_comps"
          :headers="company_headers"
          :items="search_comps"
          :server-items-length="search_total_results"
          :options.sync="options"
          :items-per-page="num_per_page"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [num_per_page],
          }"
          class="elevation-1"
          no-data-text="No companies found"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="addCompany(item)">
              mdi-plus
            </v-icon>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="finishedAddedCompanies">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="mt-4">
      <v-card-title
        >Clients<v-btn icon @click="openAddCompanies">
          <v-icon>mdi-plus</v-icon>
        </v-btn></v-card-title
      >
      <v-data-table
        v-if="clients"
        :headers="headers"
        :items="clients"
        :sort-by.sync="sortBy"
        :items-per-page="25"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 25, -1],
        }"
        class="elevation-1"
        no-data-text="No clients available"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="gotoClient(item)">
            mdi-view-list
          </v-icon>
          <v-icon small class="mr-2" @click="deleteClient(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import companies_api from '../api/companies';

export default {
  name: 'Clients',
  data() {
    return {
      addDialog: false,
      search_q: '',
      options: {},
      sortBy: 'name',
      headers: [
        { text: 'Name', value: 'name' },
        // { text: 'Turnover', value: 'essentials.turnover' },
        // { text: 'NetProfit', value: 'essentials.net_profit' },
        // { text: 'Tax', value: 'essentials.tax' },
        // { text: 'NetAssets', value: 'essentials.net_assets' },
        { text: 'Score', value: 'score' },
        { text: 'R&D', value: 'scores.R&D' },
        { text: 'CTR', value: 'scores.CTR' },
        { text: 'PB', value: 'scores.PB' },
        { text: 'CA', value: 'scores.CA' },
        { text: 'LR', value: 'scores.LR' },
        { text: 'ME', value: 'scores.ME' },
        { text: 'DA', value: 'scores.DA' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      company_headers: [
        { text: 'Name', value: 'title' },
        { text: 'Address', value: 'address_snippet' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search_comps: null,
      search_total_results: 0,
      start_index: 0,
      num_per_page: 10,
    };
  },
  computed: {
    isAuthorised() {
      // return this.$store.getters.isAuthorised
      return this.$store.getters['auth/isAuthenticated'];
    },
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('clients', ['clients', 'me', 'error', 'loading']),
    ...mapGetters('companies', ['companies']),
  },
  watch: {
    me(newValue, oldValue) {
      console.log(`Updating me from ${oldValue} to ${newValue}`);
      this.$store.dispatch('clients/loadClients');
    },
    options: {
      handler(val) {
        // console.log('options', val, oldVal);
        this.start_index = (val.page - 1) * val.itemsPerPage;
        this.getCompanies();
      },
      deep: true,
    },
  },
  methods: {
    gotoClient(item) {
      console.log('gotoClient', item.id);
      this.$router.push('/client/' + item.id);
    },
    deleteClient(item) {
      console.log('deleteClient', item.id);
      const id = item.id;
      this.$store.dispatch('clients/deleteClient', { id }).then(() => {
        this.$store.dispatch('clients/loadClients');
      });
    },
    onSearchEnter() {
      console.log('onSearchEnter', this.search_q);
      this.getCompanies();
    },
    openAddCompanies() {
      this.search_q = '';
      this.search_comps = null;
      this.search_total_results = 0;
      this.start_index = 0;
      this.addDialog = true;
    },
    getCompanies() {
      //   console.log('getCompanies', this.search_q, this.options);
      const q = this.search_q;
      //   this.$store.dispatch('companies/search', { q }).then((data) => {
      //     console.log(data);
      //     this.comps = data.items.map((item) => {
      //       return { title: item.title, address_snippet: item.address_snippet };
      //     });
      //   });
      companies_api
        .company_search(q, this.num_per_page, this.start_index)
        .then(({ data }) => {
          //   console.log(data);
          this.search_comps = data.items.map((item) => {
            return {
              title: item.title,
              address_snippet: item.address_snippet,
              company_number: item.company_number,
            };
          });
          this.search_total_results = data.total_results;
        });
    },
    addCompany(item) {
      console.log('addCompany', item);
      const num = item.company_number;
      companies_api.add_company(num).then(({ data }) => {
        console.log('added', data);
      });
    },
    finishedAddedCompanies() {
      this.addDialog = false;
      this.$store.dispatch('clients/loadClients');
    },
  },
  mounted() {
    console.log('authorised', this.isAuthenticated);
    if (this.isAuthenticated) {
      if (this.me.firm) {
        console.log(`ok`);
        this.$store.dispatch('clients/loadClients');
      } else {
        this.$store.dispatch('clients/loadMe');
      }

      console.log('Yay! Authorised');
    } else {
      console.log('Bah! Not Authorised');
      this.$router.push({ name: 'SignIn' });
    }
  },
};
</script>
