<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-toolbar-title @click="goHome()"
          ><h3>Tax On Demand</h3>
        </v-toolbar-title>

        <v-btn :to="{ name: 'Home' }" text exact>
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn :to="{ name: 'Clients' }" text>
          <span class="mr-2">Clients</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <h3 v-if="me">{{ me.firmname }}</h3>

      <v-btn v-if="isAuthenticated" to="/logout" text>
        <h3 class="mr-2">Logout</h3>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <v-btn v-else href="#" text>
        <h3 class="mr-2">Login</h3>
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('clients', ['me']),
  },
};
</script>
