<template>
  <svg
    class="scaling-svg"
    baseProfile="full"
    version="1.1"
    viewBox="0 0 400 400"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:ev="http://www.w3.org/2001/xml-events"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ref="svg"
  >
    <g :transform="`translate(${centre},${centre})`">
      <circle
        fill="none"
        :r="innerRadius"
        stroke="gray"
        :stroke-dasharray="dashArray"
        :stroke-width="strokeWidth"
        :transform="transform"
      />
      <circle
        fill="none"
        :r="innerRadius"
        stroke="#1976d2"
        :stroke-dasharray="dashArray2"
        :stroke-width="strokeWidth"
        :transform="transform"
      />
      <text class="heavy">
        {{ value }}
      </text>
    </g>
  </svg>
</template>
<script>
// based on: https://www.fullstacklabs.co/blog/creating-an-svg-gauge-component-from-scratch
const boxSize = 400;
const centre = boxSize / 2;
export default {
  name: 'ScroreGraphic',
  props: {
    thicknessFactor: Number,
    value: Number,
    maxValue: Number,
  },

  data: () => ({
    radius: 190,
    degArc: 270,
    start: 135,
    centre: centre,
  }),
  computed: {
    dashArray() {
      const circumference = this.innerRadius * 2 * Math.PI;
      const arc = circumference * this.arc;
      return `${arc} ${circumference}`;
    },
    dashArray2() {
      const circumference = this.innerRadius * 2 * Math.PI;
      const arc = (circumference * this.arc * this.value) / this.maxValue;
      return `${arc} ${circumference}`;
    },
    arc() {
      return this.degArc / 360.0;
    },
    strokeWidth() {
      return this.radius * this.thicknessFactor;
    },
    innerRadius() {
      return this.radius - this.strokeWidth / 2;
    },
    transform() {
      const rotatation = (360.0 - this.degArc) / 2 + 90;
      return `rotate(${rotatation})`;
    },
  },
};
</script>

<style scoped>
.scaling-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.heavy {
  font: 100px sans-serif;
  text-anchor: middle;
  dominant-baseline: middle;
}
</style>
