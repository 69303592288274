<template>
  <v-alert color="red" dismissible @input="onClose" :value="true">
    {{ text }}
  </v-alert>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  methods: {
    onClose() {
      this.$emit('dismissed');
    },
  },
};
</script>
