import files from '../api/files';

import {
  LOADING_BEGIN,
  LOADING_FAILURE,
  LOADING_SUCCESS,
  SET_FILE,
  SET_FILE_ROWS,
} from './types';

const initialState = {
  me: {},
  file: null,
  rows: null,
  file_loading: false,
  file_error: false,
};

const getters = {
  file: (state) => state.file,
  rows: (state) => state.rows,
  file_loading: (state) => state.file_loading,
  file_error: (state) => state.file_error,
};

const actions = {
  loadFile({ commit }, { id }) {
    commit(LOADING_BEGIN);
    commit(SET_FILE, null);
    // console.log('file');
    return files
      .loadFile(id)
      .then(({ data }) => commit(SET_FILE, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
  loadFileRows({ commit }, { id }) {
    commit(LOADING_BEGIN);
    console.log('loadFileRows');
    commit(SET_FILE_ROWS, null);
    return files
      .loadFileRows(id)
      .then(({ data }) => commit(SET_FILE_ROWS, data))
      .then(() => commit(LOADING_SUCCESS))
      .catch(() => commit(LOADING_FAILURE));
  },
};

const mutations = {
  [LOADING_BEGIN](state) {
    state.loading = true;
    state.error = false;
  },
  [LOADING_FAILURE](state) {
    console.log('LOADING_FAILURE');
    state.loading = false;
    state.error = true;
  },
  [LOADING_SUCCESS](state) {
    console.log('LOADING_SUCCESS');
    state.loading = false;
    state.error = false;
  },
  [SET_FILE](state, file) {
    // console.log('SET_FILE', file);
    state.file = file;
  },
  [SET_FILE_ROWS](state, rows) {
    // console.log('SET_FILE_ROWS', rows);
    state.rows = rows;
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
