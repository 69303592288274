import session from './session';

let baseUrl = ''; // for distribution with Django backend
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://127.0.0.1:8000';
}

export default {
  company_search(q, items_per_page, start_index) {
    let query = `q=${q}`;
    if (items_per_page) {
      query += `&items_per_page=${items_per_page}`;
    }
    if (start_index) {
      query += `&start_index=${start_index}`;
    }
    return session.get(`${baseUrl}/api/company_search/?${query}`);
  },
  add_company(num) {
    return session.post(`${baseUrl}/api/clients/add_company/`, {
      num,
    });
  },
};
